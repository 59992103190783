// src/components/Healthcare.js

import React, { useState } from "react";
import healthcare from "./../healthcare.png";
import healthcareOverview from "./../healthLanding.png";
import defaultScreen from "./../therapyscreen.png";
import healthie from "./../healthie-logo.png";
import mit from "./../MIT-LogoL.png";
import cdl from "./../cdl-program.png";
import ntuh from "./../ntuh-logo.png";
import dtf from "./../DinTaiFung-logo.png";
import hante from "./../hantepay-logo.png";
import sunmi from "./../sunmi-logo.png";
import hipaaOffice from "./../hipaa-official.png";

const Healthcare = () => {
  const handleButtonClick = () => {
    window.location.href = "https://app.embercopilot.ai";
  };

  const [isAnnual, setIsAnnual] = useState(false);

  const timeSavingsData = [
    {
      role: "Primary Care Physicians",
      stat: "33%",
      description: "Reduction in documentation time per patient encounter",
      subtext: "Reduce time spent approx.",
    },
    {
      role: "Clinicians",
      stat: "2/3",
      description:
        "Reduction in time spent on EHR, primarily for documentation",
      subtext: "Reduce",
    },
    {
      role: "Therapists",
      stat: "30%",
      description:
        "Reduction in administrative tasks, including insurance documentation",
      subtext: "Minimize the time at least",
    },
  ];

  const plans = [
    {
      name: "Free",
      price: "$0",
      description: "See How Ember Can Help",
      features: ["5 Sessions", "SOAP notes", "Desktop, Web, Mobile App"],
      btncss: "basic",
      annualPrice: "$0",
      buttonLink: "https://app.embercopilot.ai/",
      annualPlanLink: "https://app.embercopilot.ai/",
      buttonLabel: "Try for Free",
      buttonpromo: "No Credit Card Required",
    },
    {
      name: "Individual",
      price: "$49.99 USD",
      unit: "per user/month",
      annualUnit: "per user/month, billed annually",
      description: "No more manual notetaking",
      features: ["Unlimited Sessions", "Treatment Plans", "ICD-10, CPT Coding"],
      btncss: "basic",
      buttonLink: "https://app.embercopilot.ai/",
      buttonpromo: "No Credit Card Required",
      buttonLabel: "Try for Free",
      annualPrice: "$40.99 USD",
      annualPlanText: "Annual Payment Discount",
      annualPlanLink: "https://app.embercopilot.ai/",
    },
    {
      name: "Group",
      price: "",
      unit: "",
      description: "Have a group of clinicians?",
      features: [
        "EHR Integration",
        "Customized Templates",
        "Organization-Wide BAA",
      ],
      btncss: "business",
      buttonLink: "https://calendly.com/embercopilot/30min",
      buttonLabel: "Contact Us",
      annualPrice: "",
      annualPlanText: "Contact Us",
      annualPlanLink: "https://calendly.com/embercopilot/30min",
    },
  ];

  // Modify plans based on isAnnual state
  const modifiedPlans = plans.map((plan) => ({
    ...plan,
    price: isAnnual ? plan.annualPrice : plan.price,
    buttonLink: isAnnual ? plan.annualPlanLink : plan.buttonLink,
    unit: isAnnual ? plan.annualUnit : plan.unit,
    // Other modifications for annual plans go here
  }));

  const togglePricing = (annual) => {
    setIsAnnual(annual);
  };
  //   e.preventDefault();
  //   try {
  //     // Send the form data to the specified email address
  //     const emailData = {
  //       to: "charlene@embercopilot.ai",
  //       subject: "New Form Submission",
  //       body: JSON.stringify(formData, null, 2),
  //     };

  //     // Use a service or API to send the email
  //     // This is a placeholder and needs to be replaced with actual email sending logic
  //     // await sendEmail(emailData);

  //     console.log("Form data sent to:", emailData.to);
  //     // Simulating an API call delay
  //     await new Promise((resolve) => setTimeout(resolve, 1000));
  //     setIsSubmitted(true);
  //   } catch (error) {
  //     console.error("Error:", error);
  //     alert("There was an error submitting the form. Please try again.");
  //   }
  // };

  return (
    <div className="privacy-container">
      <div className="data-privacy-policy-container usecase-wrapper">
        <div className="therapy-block">
          <div style={{ marginRight: "1rem" }}>
            <h2>
              Efficient Documentation, <br /> Elevated Healthcare.
            </h2>
            <p style={{ fontSize: "1rem" }}>
              All you need to do is review, approve, and submit.
            </p>

            <button
              className="usecase-btn"
              onClick={handleButtonClick}
              style={{ width: "18rem" }}
            >
              Try for free
            </button>

            <div
              className="center-text"
              style={{ width: "17rem", marginTop: "1rem" }}
            >
              <div>
                <img
                  src={hipaaOffice}
                  alt="HIPAA Official"
                  style={{ width: "100px", height: "60px" }}
                />
              </div>
              <div>
                <a
                  href="https://app.drata.com/trust/3623ac43-e3e1-46fc-8414-2fbcf46920be"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{
                    color: "#333",
                    fontWeight: "bold",
                    fontSize: "1.2rem",
                    background: "linear-gradient(to right, #0077be, #00a3e0)",
                    WebkitBackgroundClip: "text",
                    WebkitTextFillColor: "transparent",
                  }}
                >
                  100% Compliant
                </a>
              </div>
            </div>
          </div>
          <img
            className="product-overview"
            src={healthcareOverview}
            alt="Healthcare Overview"
          />
        </div>
        <div className="below-the-fold">
          <div className="therapy-first-block">
            <div>
              <h2>How Ember Works</h2>
              <ul>
                <li>
                  <b>Transcribe: </b>Ember captures your sessions, instantly
                  transcribing them and generating visit summary, progress
                  notes, treatment plan, and billing codes.
                </li>
                <li>
                  <b>Review and Submit Seamlessly: </b>Post-session, simply
                  review the notes prepared by Ember and use our one-click
                  sharing feature to submit them to insurance or your EHR
                  effortlessly.
                </li>
              </ul>
              <div className="mobile-padding">
                <button className="usecase-btn" onClick={handleButtonClick}>
                  Start for free
                </button>
                <p className="center-text">No credit card required</p>
              </div>
            </div>
            <div>
              <img src={defaultScreen} alt="HIPAA Compliant Medical Scribe" />
            </div>
          </div>
          <div
            style={{
              textAlign: "center",
              borderRadius: "2.5rem",
              color: "white",
              background: "#25265e",
              padding: "2rem",
              marginTop: "2rem",
              marginBottom: "2rem",
              maxWidth: "60rem",
            }}
          >
            <h2
              style={{
                display: "block",
                textAlign: "center",
                marginBottom: "1rem",
              }}
            >
              Why Ember
            </h2>
            <div className="why-ember-block">
              <div className="highlight-item">
                <h2>Accuracy</h2>
              </div>
              <div className="highlight-item">
                <h2>Ease of Use</h2>
              </div>
              <div className="highlight-item">
                <h2>Security and Privacy</h2>
              </div>
            </div>
            <p
              style={{
                marginTop: "1rem",
              }}
            >
              Ember is a US-based company. All our data is stored in the US.
            </p>
          </div>
          <div className="time-savings-section">
            <h2>Save Time Across Healthcare Professions</h2>
            <div className="time-savings-grid">
              {timeSavingsData.map((item, index) => (
                <div key={index} className="time-savings-item">
                  <h3>{item.role}</h3>
                  {/* <p className="subtext">{item.subtext}</p> */}
                  <p className="stat">{item.stat}</p>
                  <p className="description">{item.description}</p>
                </div>
              ))}
            </div>
            <p className="time-savings-disclaimer">
              Data based on industry studies. Actual results may vary.
              Implementing efficient documentation systems and processes can
              help achieve these time savings.
            </p>
          </div>
          <div className="therapy-pricing-block">
            <div className="plan-selection">
              <button
                onClick={() => togglePricing(false)}
                className={isAnnual ? "" : "active"}
              >
                Monthly
              </button>
              <button
                onClick={() => togglePricing(true)}
                className={isAnnual ? "active" : ""}
              >
                Yearly
              </button>
            </div>
            <div className="pricing-container">
              {modifiedPlans.map((plan) => (
                <div className="feature-column" key={plan.name}>
                  <div className="plan-header">{plan.name}</div>
                  <p className="plan-description">{plan.description}</p>
                  <p className="price">{plan.price}</p>
                  <p className="plan-unit" style={{ fontSize: "0.9rem" }}>
                    {plan.unit}
                  </p>
                  <ul className="custom-pricing-bullet pricing-text">
                    {plan.features.map((feature, index) => (
                      <li key={index}>
                        {feature === "View All Features" && (
                          <a
                            href="https://www.embercopilot.ai/changelog"
                            target="_blank"
                            rel="noreferrer"
                            className="cta-link"
                          >
                            {feature}
                          </a>
                        )}
                        {feature !== "View All Features" && feature}
                      </li>
                    ))}
                  </ul>
                  <button
                    className={`price-btn variant-${plan.btncss}`}
                    onClick={() => window.open(plan.buttonLink, "_blank")}
                  >
                    {plan.buttonLabel}
                  </button>
                  <p className="plan-promo">{plan.buttonpromo}</p>
                </div>
              ))}
            </div>
            <div className="student-pricing-info">
              <p>
                We’re dedicated to supporting all aspiring clinicians—students
                and trainees, please email support@embercopilot.ai for
                discounts.
              </p>
            </div>
          </div>
          <div
            className="therapy-first-block"
            style={{ display: "block", textAlign: "center" }}
          >
            <h2>
              See what your peers are saying about their Ember experiences
            </h2>
            <div>
              <iframe
                className="yt-overview"
                src="https://www.youtube.com/embed/kN5ZoF9uvcQ?si=rWkorHbt1EEYAR7_"
                frameBorder="0"
                allow="autoplay; encrypted-media"
                allowFullScreen
                title="Ember Copilot Product Overview"
              ></iframe>
            </div>
            <a
              href="https://www.youtube.com/watch?v=PiRB2sIYSLs"
              target="_blank"
              rel="noreferrer"
              className="cta-link"
            >
              <p>View the full interview</p>
            </a>
          </div>
          <div className="logo-bar-section">
            <div className="logo-bar-header">Preferred by Professionals</div>
            <div className="logo-bar">
              <div className="logo-bar-content">
                <img src={mit} alt="Massachusetts Institute of Technology" />
                <img
                  src={cdl}
                  alt="Creative Destruction Lab Computational Health"
                />
                <img
                  src={ntuh}
                  alt="NTUH (National Taiwan University Hospital)"
                />
                <img src={healthie} alt="Healthie" />
                {/* <img src={maine} alt="Maine Medical Center" /> */}
                <img src={dtf} alt="Din Tai Fung 鼎泰豐" />
                <img src={hante} alt="HantePay汉特支付" />
                <img src={sunmi} alt="Sunmi 商米科技" />
                <img src={mit} alt="Massachusetts Institute of Technology" />
                <img
                  src={cdl}
                  alt="Creative Destruction Lab Computational Health"
                />
                <img
                  src={ntuh}
                  alt="NTUH (National Taiwan University Hospital)"
                />
                <img src={healthie} alt="Healthie" />
                {/* <img src={maine} alt="Maine Medical Center" /> */}
                <img src={dtf} alt="Din Tai Fung 鼎泰豐" />
                <img src={hante} alt="HantePay汉特支付" />
                <img src={sunmi} alt="Sunmi 商米科技" />
              </div>
            </div>
          </div>
          <div className="usecase-second-block">
            <div className="usecase-second-content">
              <div className="image-wrapper">
                <img
                  className="usecase-hero-image"
                  src={healthcare}
                  alt="Healthcare Overview"
                />
              </div>
              <h2 className="image-wrapper">More Healing, Less Tediousness</h2>
              <div className="image-wrapper-text">
                <p>
                  Imagine not having to spend countless hours on note-taking for
                  insurance purposes.
                </p>
                <p>
                  Artificial intelligence can never replace the genuine care and
                  expertise you provide. Your job is to help clients, not to
                  spend endless hours on paperwork.
                </p>
                <p>
                  Let Ember handle the notes and documentation for you. This
                  way, you can dedicate more time to your clients and focus on
                  what truly matters.
                </p>
              </div>
            </div>
          </div>

          <div className="usecase-second-block" style={{ marginTop: "2rem" }}>
            <div className="usecase-cta">
              <h2 className="image-wrapper">
                Let Ember Remember, So You Can Focus on Care
              </h2>
              <div
                className="cta-btn-container"
                style={{ gap: "1rem", marginBottom: "0" }}
              >
                <div className="end-btn-wrapper">
                  <button className="usecase-btn" onClick={handleButtonClick}>
                    Get Started
                  </button>
                </div>
                <p className="center-text">
                  <a
                    href="https://app.drata.com/trust/3623ac43-e3e1-46fc-8414-2fbcf46920be"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ color: "#fff" }}
                  >
                    100% HIPAA Compliant
                  </a>
                </p>
              </div>
            </div>
          </div>

          <div className="demo-section">
            <div className="demo-info">
              <h4>Book a demo</h4>
              <h3>Empower Your Team with Ember Copilot</h3>
              <p>
                Ready to take your practice to the next level? Bring your team
                on board and explore how Ember can revolutionize your workflow.
                From automatic session transcription to easy note submission,
                Ember ensures everyone on your team can work more efficiently.
                Experience the seamless integration with your EHR and see how
                Ember can empower your team to handle even the most complex
                tasks with ease.
              </p>
            </div>
            <div className="demo-form">
              <div className="demo-form">
                <iframe
                  className="airtable-embed"
                  src="https://airtable.com/embed/appcx1DPOq5OhbmfY/paggJb3LzcAtz8UcC/form"
                  frameBorder="0"
                  onmousewheel=""
                  title="Ember Copilot Book a Demo"
                  height="680"
                  style={{
                    background: "transparent",
                    width: "200%",
                  }}
                ></iframe>
                <style>
                  {`
                      @media (max-width: 768px) {
                        .airtable-embed {
                          width: 100% !important;
                          height: 740px;
                        }
                      }
                    `}
                </style>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Healthcare;
