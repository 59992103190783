import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import "./../App.css";
import MenuIcon from "@mui/icons-material/Menu";
import emberLogo from "./../EmberAI.png";

const NavBar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 850);
  const location = useLocation();

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 850);
      if (window.innerWidth > 850) {
        setIsOpen(false);
      }
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const handleButtonClick = () => {
    window.location.href = "https://app.embercopilot.ai";
  };

  const handleScheduleClick = () => {
    window.location.href = "https://calendly.com/embercopilot/30min";
  };

  const getLinkClassName = (path) => {
    let className = "nav-link";
    if (location.pathname === path) {
      className += " active";
    }
    return className;
  };

  return (
    <nav className="navbar">
      <div
        className={`hamburger ${isMobile ? "" : "hidden"}`}
        onClick={toggleMenu}
      >
        <MenuIcon fontSize="large" />
      </div>
      <div className="product-logo">
        <a href="/">
          <img src={emberLogo} alt="Ember Copilot" />
        </a>
      </div>
      <div
        className={`nav-links ${isOpen && isMobile ? "open" : ""} ${
          isMobile ? "mobile" : ""
        }`}
      >
        <Link
          to="/healthcare"
          className={getLinkClassName("/healthcare")}
          onClick={() => isMobile && toggleMenu()}
        >
          Product
        </Link>
        <Link
          to="/pricing"
          className={getLinkClassName("/pricing")}
          onClick={() => isMobile && toggleMenu()}
        >
          Pricing
        </Link>
        <a
          href="https://help.embercopilot.ai/en"
          className={getLinkClassName("/support")}
          onClick={() => isMobile && toggleMenu()}
          target="_blank"
          rel="noopener noreferrer"
        >
          Support
        </a>
      </div>
      <div className="login-btn-wrapper">
        <button
          type="submit"
          className="demo-btn"
          onClick={handleScheduleClick}
        >
          Schedule a demo
        </button>
        <button type="submit" className="login-btn" onClick={handleButtonClick}>
          Log In
        </button>
      </div>
    </nav>
  );
};

export default NavBar;
