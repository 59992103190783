import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import "./ReferralLanding.css";

const referralMapping = {
  X7KP9Q: {
    paymentLink: "https://buy.stripe.com/bIY4i65Je0LtdSUaES",
    promotionalText:
      "Dr. Akhu thinks you'll love Ember Copilot, and we agree! As a special offer for Dr. Akhu's friends, you'll get your first month free.",
  },
  Y9F2KL: {
    paymentLink: "https://buy.stripe.com/28o01Q0oU65NbKM4gv",
    promotionalText:
      "As a special offer for healthcare professionals in Taiwan, enjoy 90 days of free access to Ember Copilot.",
  },
  // Add more referral codes and their corresponding data here
};

const ReferralLanding = () => {
  const [referralCode, setReferralCode] = useState("");
  const [referralData, setReferralData] = useState(null);
  const location = useLocation();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const code = params.get("code");
    if (code && referralMapping[code]) {
      setReferralCode(code);
      setReferralData(referralMapping[code]);
    } else {
      window.location.href = "/";
    }
  }, [location]);

  const handleCTA = () => {
    if (referralData) {
      window.open(referralData.paymentLink, "_blank");
    }
  };

  return (
    <div className="referral-container">
      <h3 className="referral-title">
        You've been invited to try Ember Copilot!
      </h3>
      <p className="referral-text">
        {referralData ? referralData.promotionalText : ""}
      </p>
      <p className="referral-text">
        Ember Copilot is the AI Scribe that saves you 2+ hours daily on
        documentation. Let us take care of your SOAP notes, so you can focus on
        what matters most.
      </p>
      <p className="referral-code">
        Your unique referral code:{" "}
        <span className="referral-code-value">{referralCode}</span>
      </p>
      <div className="referral-cta-container">
        <button onClick={handleCTA} className="referral-cta-button">
          Claim Your Special Offer
        </button>
      </div>
    </div>
  );
};

export default ReferralLanding;
