import React from "react";
import XIcon from "./../logo-white.png";
import youtube from "./../youtube.png";
import linkedin from "./../linkedin.png";

const BottomNav = () => {
  return (
    // <nav className="bottom-nav">
    <footer className="footer">
      <div className="container">
        <div className="column logo">
          <h3>Ember Copilot</h3>
        </div>
        <div className="column">
          <h3>Products</h3>
          <div>
            <a
              href="https://app.embercopilot.ai/"
              target="_blank"
              rel="noreferrer"
            >
              Web
            </a>
          </div>
          <div>
            <a
              href="https://apps.apple.com/us/app/ember-ai/id6478905562"
              target="_blank"
              rel="noreferrer"
            >
              iPhone
            </a>
          </div>
          <div>
            <a
              href="https://apps.apple.com/us/app/ember-ai/id6478905562"
              target="_blank"
              rel="noreferrer"
            >
              Desktop
            </a>
          </div>
        </div>
        <div className="column">
          <h3>Company</h3>
          <div>
            <a
              href="https://help.embercopilot.ai/en"
              target="_blank"
              rel="noopener noreferrer"
            >
              Help Center
            </a>
          </div>
          <div>
            <a href="/faq" target="_blank" rel="noreferrer">
              FAQs
            </a>
          </div>
          <div>
            <a
              href="https://airtable.com/appWFK9MTjsVSbMxC/pagcfcLkhvmpG6uVi/form"
              target="_blank"
              rel="noreferrer"
            >
              Ambassador Program
            </a>
          </div>
        </div>
        <div className="column">
          <h3>Legal</h3>
          <div>
            <a
              href="https://app.drata.com/trust/3623ac43-e3e1-46fc-8414-2fbcf46920be"
              target="_blank"
              rel="noreferrer"
            >
              Trust Center
            </a>
          </div>
          <div>
            <a href="/privacy" target="_blank" rel="noreferrer">
              Privacy Policy
            </a>
          </div>
          <div>
            <a href="/terms" target="_blank" rel="noreferrer">
              Terms of Service
            </a>
          </div>
        </div>
        <div className="column">
          <div>
            <div className="social-banner">
              <a
                href="https://twitter.com/embercopilotAI"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src={XIcon}
                  className="x-icon"
                  alt="Ember Copilot Twitter/X"
                />
              </a>
              <a
                href="https://www.youtube.com/@EmberCopilot"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src={youtube}
                  className="youtube-icon"
                  alt="Ember Copilot YouTube"
                />
              </a>
              <a
                href="https://www.linkedin.com/company/embercopilot"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src={linkedin}
                  className="youtube-icon"
                  alt="Ember Copilot LinkedIn"
                />
              </a>
            </div>
          </div>
          <div>
            <a href="/contact" target="_blank" rel="noreferrer">
              Contact us
            </a>
          </div>
          <div>
            <a
              href="https://stats.uptimerobot.com/GmYKRIWx9k"
              target="_blank"
              rel="noreferrer"
            >
              Status page
            </a>
          </div>
          <div>
            <a href="/responsible" target="_blank" rel="noreferrer">
              Responsible AI
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default BottomNav;
